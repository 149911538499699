$(function () {
    renderFooter();
});
// Show footer
function renderFooter() {
    initFooter();
    $(window).on("scroll", showFooterCore);

    function initFooter() {
        if (needFooter()) {
            $("footer").show();
        } else {
            $("footer").hide();
        }
    }

    function showFooterCore() {
        if (needFooter()) {
            $("footer").fadeIn();
        } else {
            $("footer").fadeOut();
        }
    }

    function needFooter() {
        var scrollHeight = $(document).height();
        var scrollPosition = $(window).height() + $(window).scrollTop();
        return (scrollHeight - scrollPosition) < 1;
    }
}